import React from 'react'
import { Link } from 'gatsby'
import './image-banner.scss'

export default function ImageBanner(props) {
  return (
  <Link to={props.buttonPrimaryUrl}>
    <div className="image-banner"  style={{backgroundImage: `url(${props.image})`}}>
        <div className="container-fluid">
            <div className='image-banner__block1'>
                <h2 className="image-banner__heading">{props.headingText}</h2>
                <div className="image-banner__button-container">
                    {props.buttonPrimaryText &&
                        <button to={props.buttonPrimaryUrl} className="button-tertiary">{props.buttonPrimaryText}
                            <div className="image-banner__button-tertiary-arrow">
                                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0438 0.416672L13.1828 1.30258L16.8358 5.0611H0.958374V6.31403H16.8357L13.1828 10.0724L14.0438 10.9583L19.1667 5.68751L14.0438 0.416672Z" fill="black"/>
                                </svg>
                            </div>
                        </button>
                    }
                </div>
            </div>
            <div className='image-banner__block2'>
                {props.descriptionText &&
                    <div className="image-banner__description-container">{props.descriptionText}</div>
                }
            </div>
        </div>
        <div className="image-banner__dark-background"></div>
    </div>
    </Link>
  )
}
