import React from 'react'
import './multicolumn-banners.scss'

export default function MulticolumnBanners({ banners }) {
    if (!banners) return null
    if (!Array.isArray(banners)) return null
    return (
        <div className="multicolumn-banners">
            <div className="container-fluid">
                <div className="multicolumn-banners__container">
                    {banners.map((banner) => {
                        return (
                            <a href='#'>
                                <div className='multicolumn-banners__block' style={{ backgroundImage: `url(${banner.image.url})` }}>
                                    <div className="multicolumn-banners__icon">
                                        <img src={banner.icon.url} width="40" height="40" alt="An eComplete icon" title="An eComplete icon"/>
                                    </div>
                                    <h2 className="multicolumn-banners__block-heading">{banner.title}</h2>
                                    <div className="multicolumn-banners__button-tertiary-arrow">
                                        <svg width="28" height="35" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.9192 0L23.2795 1.68707L30.2361 8.84461H0V11.2306H30.2359L23.2795 18.3879L24.9192 20.075L34.675 10.0375L24.9192 0Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
