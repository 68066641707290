import React from 'react'
import './stats-block.scss'

export default function StatsBlock({ stats }) {
    if (!stats) return null
    if (!Array.isArray(stats)) return null
    return (
        <div className="stats-block-container one">
            {stats.map((stats) => {
                return (
                    <div className="stats-block">
                        <div className="stats-block__block">
                            <div className="stats-block__block-row">
                                <p className="stat">{stats.statOne}</p>
                                <div className="icon-container">
                                    <img src={stats.statIconOne.url} className="icon-standard" alt="Stat Icon" title="Stat Icon"/>
                                </div>
                            </div>
                            <p className="subtitle">{stats.subStatOne}</p>
                        </div>
                        <div className="stats-block__block">
                            <div className="stats-block__block-row">
                                <p className="stat">{stats.statTwo}</p>
                                <div className="icon-container">
                                    <img src={stats.statIconTwo.url} className="icon-standard" alt="Stat Icon" title="Stat Icon"/>
                                </div>
                            </div>
                            <p className="subtitle">{stats.subStatTwo}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
