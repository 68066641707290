import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import './component-showcase.scss'


import Layout from '../components/layout';
import PrimaryBanner from '../components/primary-banner/primary-banner'
import bannerImage from "../images/primary-banner--home.png"
import BrandLogos from '../components/brand-logos/brand-logos'
import ImageBanner from '../components/image-banner/image-banner'
import servicesImage1 from "../images/image-banner--services_1.jpg"
import TextBanner from '../components/text-banner/text-banner'
import Multicolumn from '../components/multicolumn/multicolumn'
import FiftyfiftyTextBanner from '../components/fiftyfifty-text-banner/fiftyfifty-text-banner'
import MulticolumnBanners from '../components/multicolumn-banners/multicolumn-banners'
import StatsBlock from '../components/stats-block/stats-block'
import ClientBlock from '../components/client-block/client-block'
import OverlayBlock from '../components/overlay-block/overlay-block'
import overlayBlockImage from '../images/overlay_block-about.jpg'
import ImageBackground from '../images/contactBackground.webp'

import ItemEditorial from '../components/6item-editorial/6item-editorial'
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team'
import SolutionsBlock from '../components/solutions-block/solutions-block'
import TestimonialBlock from '../components/testimonial-block/testimonial-block'
import StaffMembersBlock from '../components/staff-block/staff-block'
import ContactForm from '../components/contact-form/contact-form'

class ComponentShowcase extends React.Component {
  render() {
    const showcaseQueryStatsBlockOne = get(this, 'props.data.showcaseQueryStatsBlockOne');
    const showcaseMulticolumnHeading = get(this, 'props.data.showcaseMulticolumnHeading');
    const showcaseBrandLogos = get(this, 'props.data.showcaseBrandLogos');
    const showcaseMulticolumnComponent = get(this, 'props.data.showcaseMulticolumnComponent');
    const showcaseMulticolumnBanner = get(this, 'props.data.showcaseMulticolumnBanner');
    const showcaseClientBlock = get(this, 'props.data.showcaseClientBlock');
    const allContentfulBlogPost = get(this, 'props.data.allContentfulBlogPost');
    const showcaseOverlayBlockTeam = get(this, 'props.data.showcaseOverlayBlockTeam');
    const showcaseStaffMembersBlock = get(this, 'props.data.showcaseStaffMembersBlock');
    const showcaseSolutionsBlock = get(this, 'props.data.showcaseSolutionsBlock');
    const showcaseTestimonialBlock = get(this, 'props.data.showcaseTestimonialBlock');

    return (
      <Layout location={this.props.location}>
          <div className="container-fluid showcase-page">
            <h1>Component Showcase</h1>
            <h2>Primary Banner</h2>
            <PrimaryBanner headingText="test" image={bannerImage} buttonPrimaryText="test" buttonSecondaryText="test" />

            <h2>Primary Banner - No text or buttons and Overlay Block</h2>
            <PrimaryBanner image={bannerImage} />
            <OverlayBlock introText="We provide" headingText="The complete eCommerce solution powered by data, talent and global infrastructure." description1Text="Partner with eComplete to acheive your eCommerce goals, driving tangible, long term growth." description2Text="eComplete offer an end-to-end eCommerce solution that powers rapid, profitable expansion" buttonText="Our Services" image={overlayBlockImage} />

            <h2>Logos Multicolumn</h2>
            <BrandLogos heading={showcaseMulticolumnHeading} logos={showcaseBrandLogos.nodes} />

            <h2>Image Banner</h2>
            <ImageBanner headingText="Website & Digital Services" image={servicesImage1} buttonPrimaryText="get in touch" descriptionText="Services include day-to-day ecommerce trading, CRO & UX, technology services, web development, content marketing, brand marketing and digital marketing including paid search, paid social, display & promgramatic, affiliates, influencer marketing, CRM, SEO." />

            <h2>Text Banner</h2>
            <TextBanner textOne="About Us" textTwo="eComplete provides the solution to all your business needs" />

            <h2>50/50 Text Banner</h2>
            <FiftyfiftyTextBanner bannerTextLeft="The eCommerce space is more competive than ever and the current market is putting pressure on businesses to make cost-saving efficiences."
              bannerTextRight="eComplete works with your business, diving deep into the data to put in place rapid growth strategies with benefits that would usually be reserved for $500M+ ecommerce giants with the help our our robust network."
              buttonPrimaryText="our services" buttonSecondaryText="get in touch" />

            <h2>Stats Block</h2>
            <StatsBlock stats={showcaseQueryStatsBlockOne.nodes} />

            <h2>Multicolumn</h2>
            <Multicolumn multicolumnHeading={showcaseMulticolumnHeading} multicolumn={showcaseMulticolumnComponent.nodes} />

            <h2>Multicolumn Banners</h2>
            <MulticolumnBanners banners={showcaseMulticolumnBanner.nodes} />

            <h2>Client Block</h2>
            <ClientBlock heading={showcaseMulticolumnHeading} clients={showcaseClientBlock.nodes} />

            <h2>Blog</h2>
            <ItemEditorial posts={allContentfulBlogPost.nodes} />

            <h2>Team Block</h2>
            <OverlayBlockTeam image={showcaseOverlayBlockTeam.backgroundImage.url} headingText={showcaseOverlayBlockTeam.headingText}
              descriptionText={showcaseOverlayBlockTeam.descriptionText} boxOneNumber={showcaseOverlayBlockTeam.boxOneNumber}
              boxOneText={showcaseOverlayBlockTeam.boxOneText} boxTwoNumber={showcaseOverlayBlockTeam.boxTwoNumber}
              boxTwoText={showcaseOverlayBlockTeam.boxTwoText} />

            <h2>Solutions Block</h2>
            <SolutionsBlock solutions={showcaseSolutionsBlock} />

            <h2>Testimonials Block</h2>
            <TestimonialBlock testimonials={showcaseTestimonialBlock.nodes} />

            <h2>Staff Members Block</h2>
            <StaffMembersBlock members={showcaseStaffMembersBlock.nodes} />

            <h2>Contact Form</h2>
            <ContactForm heading1Text="Directly" heading2Text="Or fill out our form" email="enquiries@ecomplete.com" addressLine1="PROFOLK" addressLine2="Bank Chambers" addressLine3="Stockport" addressLine4="SK1 1AR" formName="Name" formEmail="email" formCompany="Company" formTelephone="Telephone" formMessage="Message" buttonText="Submit" image={ImageBackground} />
          </div>
      </Layout>
    )
  }
}

export default ComponentShowcase;

export const query = graphql`
query showcaseQuery {
  showcaseQueryStatsBlockOne: allContentfulStatsBlockOne {
        nodes {
            statOne
            statIconOne {
                url
            }
            subStatOne
            statTwo
            statIconTwo {
                url
            }
            subStatTwo
        }
  }
  showcaseBrandLogos: allContentfulBrandLogos {
    nodes {
      siteLink
      image {
        url
      }
    }
  }
  showcaseMulticolumnHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "showcaseMulticolumnHeading"}) {
    titleText
    descriptionText
  }
  showcaseMulticolumnComponent: allContentfulMulticolumnComponent {
    nodes {
      headingText
      labelText
      image {
        url
      }
      descriptionText
    }
  }
  showcaseMulticolumnBanner: allContentfulMulticolumnBanner {
    nodes {
      title
      image {
        url
      }
      icon {
        url
      }
    }
  }
  showcaseClientBlock: allContentfulClientBlock {
      nodes {
          image {
              url
          }
      }
  }
  allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
    nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
            url
        }
        description {
            raw
        }
    }
  }
  showcaseOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "showcaseOverlayBlockTeam"}) {
    overlayBlockName
    headingText
    descriptionText
    boxOneNumber
    boxOneText
    boxTwoNumber
    boxTwoText
    backgroundImage {
        url
    }
  }
  showcaseStaffMembersBlock: allContentfulStaffMembersBlock {
      nodes {
          image {
              url
          }
          name
          description
      }
  }
  showcaseSolutionsBlock: contentfulSolutionsBlock(solutionsBlockName: {eq: "showcaseSolutionsBlock"}) {
    titleOne
    descriptionOne
    iconOne {
      url
    }
    titleTwo
    descriptionTwo
    iconTwo {
      url
    }
    titleThree
    descriptionThree
    iconThree {
      url
    }
  }
  showcaseTestimonialBlock: allContentfulTestimonialBlock {
    nodes {
        quote
        clientName
        positionAndCompany
    }
  }
}
`
