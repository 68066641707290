import React from 'react'
import { Link } from 'gatsby'
import "./fiftyfifty-text-banner.scss"

export default function FiftyfiftyTextBanner(props) {
  return (
    <div className="ff-text-banner">
        <div className="container-fluid">
            <div className="ff-text-banner__left">
                <p className="subtitle">{props.bannerTextLeft}</p>
            </div>
            <div className="ff-text-banner__right">
                <p>{props.bannerTextRight}</p>
                <div className="ff-text-banner__right__button-container">
                    {props.buttonPrimaryText &&
                        <Link to={props.buttonPrimaryUrl} className="button-primary">{props.buttonPrimaryText}</Link>
                    }
                    {props.buttonPrimaryText &&
                        <Link to={props.buttonSecondaryUrl} className="button-secondary button-secondary--dark">{props.buttonSecondaryText}</Link>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
