import React from 'react'
import { Link } from 'gatsby'
import './multicolumn.scss'

export default function Multicolumn(props) {
    if (!props.multicolumn) return null
    if (!Array.isArray(props.multicolumn)) return null

    const ConditionalLink = ({ children, to, condition }) => (!!condition && to)
        ? <Link className="multicolumn__link" to={to}>{children}</Link>
        : <>{children}</>;


    return (
        <div className="multicolumn">
            <div className="container-fluid">
                <h2 className="multicolumn__heading">{props.multicolumnHeading.titleText}</h2>
                <p className="multicolumn__heading-description">{props.multicolumnHeading.descriptionText}</p>
                <div className="multicolumn__container">
                    {props.multicolumn.map((multicolumn) => {
                        console.log(multicolumn.url)
                        return (
                            <>
                                <div className='multicolumn__block'>
                                    <ConditionalLink to={multicolumn.url} condition={multicolumn.url}>
                                        <h2 className="multicolumn__block-heading">{multicolumn.headingText}</h2>
                                        <div className='multicolumn__block-image-container'>
                                            {multicolumn.labelText &&
                                                <div className="multicolumn__label">
                                                    <h3>{multicolumn.labelText}</h3>
                                                </div>
                                            }

                                            <img src={multicolumn.image.url} width="255" height="200" title="Phase Image" alt="PhaseImage" />

                                        </div>
                                        <p className="multicolumn__block-description">{multicolumn.descriptionText}</p>
                                    </ConditionalLink>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
