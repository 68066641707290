import React from 'react'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import './6item-editorial.scss'

export default function Itempost({ posts }) {
  if (!posts) return null
  if (!Array.isArray(posts)) return null
  return (
    <div className="item-editor container-fluid-item">
      {posts.map((post) => {
        return (
          <Link className="item-editor__block" to={`/blog/${post.slug}`}>
            <div className="item-editor__block-img">
              <img src={post.heroImage.url} width="360" height="290" title="Post Image" alt="PostImage"/>
            </div>
            <div className="item-editor__block-bottom">
              <div className="item-editor__block-bottom-info">
                <h3 className="item-editor__block-bottom-info-title">{post.title}</h3>
                <div className="item-editor__block-bottom-info-description">
                  {post.description?.raw && renderRichText(post.description)}
                </div>
              </div>
              <Link to={`/blog/${post.slug}`}>
                <button className="item-editor__block-bottom-button">Read More
                  <div className="item-editor__block-bottom-button-svg">
                    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.9621 0.38208L13.0517 1.31876L16.9141 5.2927H0.126709V6.61743H16.914L13.0517 10.5912L13.9621 11.5279L19.3786 5.955L13.9621 0.38208Z"/>
                    </svg>
                  </div>
                </button>
              </Link>
            </div>
          </Link>
        )
      })}
    </div>
  )
    }