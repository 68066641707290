import React from "react";
import './client-block.scss'

export default function Clientblock( props ) {
    if (!props.clients) return null
    if (!Array.isArray(props.clients)) return null
    return (
        <div className="client-block">
            <div className="container-fluid">
                <h2 className="client-block__heading">{props.heading.titleText}</h2>
                <div className="client-block__container">
                    {props.clients.map((clients) => {
                        return (
                            <div className="client-block__container-image">
                                <img src={clients.image.url} width="211px" height="78px" title="Client Logo" alt="Client Logo"/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
