import React from 'react'
import './brand-logos.scss'

export default function Brandlogos( props ) {
    if (!props.logos) return null
    if (!Array.isArray(props.logos)) return null
    return (
        <div className="brand-logos">
            <div className="container-fluid">
                <div className="brand-logos__heading">
                    <h2 className="brand-logos__heading-text">{props.heading.titleText}</h2>
                </div>
                <div className="brand-logos_container">
                    {props.logos.map((logo, index) => {
                        return (
                            <div key={index} className="brand-logos_container--image">
                                <a href={logo.siteLink} target="_blank" rel="noreferrer">
                                    <img src={logo.image.url} width="300" height="49" alt="Brand Logo" title="Brand Logo Image"/>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}